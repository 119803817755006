import React from "react";
import "./Bio.css";
import fotob from "../assets/fotobio.jpg"

const Bio = () => {
  return (
    <div className="textcontainer">
      <div className="titolobio">
        <h2> BIO </h2>
      </div>
      <div className="testobio">
        <h1>
Emanuela Auricchio, classe 1998, è un'artista visiva e street artist partenopea. Attiva sul territorio dal 2021, si laurea in Pittura all’Accademia di Belle Arti di Napoli integrando la sua formazione all’Academia de Bellas Artes di Granada. Prosegue a Bologna, presso Alma Mater Studiorum, per poi tornare alla sua terra d’origine iscrivendosi al biennio specialistico in Didattica dell’Arte e Mediazione del Patrimonio Artistico. Il suo lavoro indaga la forza espressiva dal colore attraverso una pittura dalle pennellate stridenti o a larghe campiture, la cui ispirazione verte su un interesse processuale, utilizza mixed media sino ad attraversare forme d'arte partecipata. La relazione tra l'opera e il pubblico costituisce un elemento fondamentale della sua ricerca, che si sviluppa in laboratori ed un progetto di street art dal nome  <i>cassandra parla</i>. Successivamente espone in solo exibition con  <i>Striature</i> nei i locali della Salumeria Upnea di Napoli, in collettiva con la mostra  <i>5 Fragments </i> presso la galleria FrameArsArtes, collabora inoltre con varie associazioni come Less Onlus e M.I.Q. a Napoli, cittə cittə ODV Taranto, è ospite a diversi eventi come Limen Salerno festival, Spaziale festival di Agropoli, Magazzini sul Po' a Torino, al comune di Castellarano, Reggio Emilia, e al teatro Toniolo di Mestre. Dal 2023 è co-fondatrice di  <i>Tras Lab </i>, collettivo d'arte e ricerca del contemporaneo nel centro di Napoli.

    
{" "}
        </h1>
      </div>
  <div className="fotobio">
            <img src={fotob} alt="primom" />
    </div>
    </div>
  );
};

export default Bio;
