import "./Curriculum.css";

const Curriculum = () => {
  return (
    <>
    <div className="operecontainer">
      <div className="operecontainerin">

     <div className="venti">
      <h1> 2020</h1></div>
      <div className="venticurriculum">
        <h4>   -<i>Cuerpos Narrativo</i> <br />
        mostra collettiva
        Centro Cìvico Zaidìn/
        Granada
</h4>
      </div>


      <div className="ventidue">
      <h1> 2022</h1> </div>
      <div className="ventiduecurriculum">
        <h4>  -<i>Rinascere</i>, mostra collettiva, Pinacoteca comunale / Positano<br />
- Live painting performance, Lago Diana / Caserta<br />
- Emanuela Auricchio, mostra personale, Covo 17 / Aversa<br />
- <i>STRIATURE</i>mostra personale, Salumeria Upnea / Napoli<br />
- Video proiezione collettiva, concorso Artisti Contemporanei, Carrousel du Louvre / Parigi<br />
- HUB-ERGO mostra e workshop del progetto Cassandra Parla, Real Albergo dei poveri / Napoli

</h4>
      </div>


      <div className="ventitre">
      <h1> 2023</h1>  </div>
      <div className="ventitrecurriculum">
        <h4>   -<i>L’Otto e 3/4</i>, mostra collettiva, spazio comunale Piazza Forcella - biblioteca Annalisa Durante / Napoli<br />
- Live painting performance, Reggia di Caserta Outlet / Caserta<br />
- <i>Intersezioni d’anime</i>, mostra collettiva, SpazioTRA / Napoli<br />
- Laboratorio e workshop in collaborazione con LESS Onlus e M.I.Q. , Via Fiumicello a Loreto / Napoli<br />
- <i>5 Fragments</i> Arte Giovane, mostra collettiva, galleria FrameArsArtes / Napoli<br />
- Laboratorio e workshop in collaborazione con LESS Onlus, spazio comunale di Procida / Procida<br />
- Partecipazione al LIMEN Festival / Salerno<br />
- Live painting performance, Festival SPAZIALE / Agropoli<br />
- Mostra collettiva di street art del progetto Cassandra Parla in collaborazione con Open House Napoli / Napoli<br />
- Inaugurazione <i>Tras’ Lab</i> di cui è co-fondatrice, mostra collettiva / Napoli<br />

</h4>
      </div>

      <div className="ventiquattro">
      <h1> 2024</h1></div>
      <div className="ventiquattrocurriculum">
        <h4>   - Laboratorio e workshop del progetto Cassandra Parla, Magazzini sul Po’ / Torino<br />
- Live painting performance e mostra personale, Piazzale Cairoli di Castellarano / Reggio Emilia<br />
- Live painting performance, Teatro Toniolo / Mestre <br />
</h4>
      </div>

      <div className="bottonedietro">
                            
                            <a href="Home">
          Torna alla home
        </a>
        
              </div>  
      </div>
      </div>

    </>
  );
};

export default Curriculum;
