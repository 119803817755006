import "react-toastify/dist/ReactToastify.css";
import Contatti from "../routes/Contatti";
import freccia from "../assets/Freccia.svg";
import "../routes/Ispirami.css";

const Ispirami = () => {
  return (
    <>
      <div className="titoloIspirami">
        <h1> ISPIRAMI </h1>{" "}
      </div>
      <div className="testoIspirami">
       
        <h4>
           La mia ricerca visiva è aperta a commissioni,
    alla vendita dei lavori in visione nel portfolio,
    allo scambio artistico e al dialogo.
    Lavoro come performer di live painting,
    accompagno eventi con sessioni individuali o collettive di pittura e
      creo laboratori artistici adatti a varie fasce d’età collaborando sia con istituzioni che privati. 
          Il contatto con il pubblico rappresenta la mia esperienza più importante,
          elaboro percorsi inclusivi e accessibili
    proponendomi inoltre come mediatrice culturale,
          in linea con la mia esperienza formativa accademica.
          Vivo l'arte in tutte le sue sfumature
          e questo mi permette di avere una visione più ampia
          come artista visuale aperta a nuove opportunità.
Ispirami con delle proposte!


        </h4>{" "}
      </div>
 <div className="boxcont">
        <Contatti />
      </div>
    </>
  );
};

export default Ispirami;

