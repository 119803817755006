import React from "react";
import caramanu from "../assets/studi/1caramanu.jpg";
import nelleforme from "../assets/slidemenu/studi.jpg";
import salvatore from "../assets/studi/3salvatore.jpg";
import studiodisal from "../assets/studi/4studiodisal.jpg";
import lacarezza from "../assets/studi/5lacarezza.jpg";
import "./Ritratti.css";

const Studi = () => {
  return (
    <div className="operecontainer">
      <div className="operecontainerin">
        <div className="gallery2">
       
            <img src={caramanu} alt="Heroides1" width="600" height="400" />
         
          <div class="desc">
            {" "}
            CARA MANU <br />
            tecnica mista e collage su tela <br />
            30 x 40 cm <br />
            2022
          </div>
        </div>
        <div className="gallery3">
          
            <img src={nelleforme} alt="Forest" width="600" height="400" />
          
          <div class="desc">
            NELLE FORME DI UN ALTRO DOLORE <br />
            tecnica mista, gesso e foglia oro su tela <br />
            90 cm <br />
            2022
          </div>
        </div>
        <div className="gallery4">
         
            <img
              src={salvatore}
              alt="Northern Lights"
              width="600"
              height="400"
            />
         
          <div class="desc">
            SALVATORE <br />
            olio, tela grezza e gesso su tela <br />
            100 x 100 cm <br />
            2019
          </div>
        </div>
        <div className="gallery5">
         
            <img src={studiodisal} alt="Mountains" width="600" height="400" />
          
          <div class="desc">
            STUDIO DI SALVATORE <br />
            olio su tela <br />
            40 x 50 cm <br />
            2020
          </div>
        </div>
      <div className="gallery12">
                            
                            <a href="Home">
          Torna alla home
        </a>
        
              </div>
              
      </div>
    </div>
  );
};

export default Studi;
