import React from "react";
import Background from "../assets/background.mp4";

import "./Trailer.css";

const Trailer = () => {
  return (
    <section class="videocontain">
      <div class="video-wrap">
        <video src={Background} playsInline autoPlay muted loop></video>
      </div>
      <div class="overlay"></div>

      <div class="landing-text">
        <a href="Home" class="enterbtn">
          Enter
        </a>
      </div>
    </section>
  );
};


export default Trailer;

