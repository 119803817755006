import "./FormStyles.css";
import "react-toastify/dist/ReactToastify.css";
import Contatti from "../routes/Contatti";
import freccia from "../assets/Freccia.svg";

const Form = () => {
  return (
    <>
      <div className="titolocontatti">
        <h1> CONTATTI </h1>{" "}
      </div>
      <div className="indicazione">
        <h4>
          {" "}
          Compila il form <br />o clicca sui link <br /> a mail e Instagram{" "}
        </h4>{" "}
      </div>
      <div className="boxcont">
        <Contatti />
      </div>
    </>
  );
};

export default Form;

