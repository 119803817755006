import React from "react";
import HeroImg from "../components/HeroImg";
import "./Home.css";

const Home = () => {
  return (
    <div>
      <div>
        <HeroImg />
      </div>
    </div>
  );
};

export default Home;
