import "./Navbar.css";
import freccia from "../assets/Freccia.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

import Logo from "../assets/Logo.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const location = useLocation();

  if (location.pathname === "/") {
    return null;
  }

  window.addEventListener("scroll", changeColor);

  return (
    <div className={color ? "header header-bg" : "header"}>
      <Link to="/">
        <div className="img-stacktop">
          <img src={Logo} className="icona" alt="true" />
        </div>
      </Link>
      <ul
        className={click ? "nav-menu active" : "nav-menu"}
        onClick={() => setClick((prevState) => !prevState)}
      >
        <li>
          <Link to="/Home" className="Nav1">
            Home
          </Link>
        </li>
        <li>
          <Link to="/Bio" className="Nav1">
            Bio
          </Link>
        </li>
        <li>
          <Link to="/Statement" className="Nav1">
            Statement
          </Link>
        </li>
        <li>
          <Link to="/Curriculum" className="Nav1">
            Curriculum
          </Link>
        </li>
        <li>
          <Link to="/Form" className="Nav1">
            Contatti
          </Link>
        </li>

       
         
      </ul>
      <div className="diranimazione">
        <img src={freccia} alt="direzione" />
           <h4>
          trascina a destra
          <br /> 
          per esplorare 
        </h4>
        <p>
          {" "}
          All Rights Reserved © 2023 
          <br /> Emanuela Auricchio
        </p>
      </div>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={30} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={30} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
