import React from "react";
import "./index.css";
import Home from "./routes/Home";
import Curriculum from "./routes/Curriculum";
import Bio from "./routes/Bio";
import Trailer from "./routes/Trailer";
import Form from "./routes/Form";
import Ritratti from "./routes/Ritratti";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Studi from "./routes/Studi.js";
import Cassandra from "./routes/Cassandra.js";
import Artemisia from "./routes/Artemisia.js";
import Ispirami from "./routes/Ispirami.js";
import Statement from "./routes/Statement.js";


function App() {
  window.scroll({
    behavior: "smooth"
  });

  return (
    <>
      <Routes>
        <Route path="/" element={<Trailer />} />
      </Routes>
      <div class="outer-wrapper">
        <div class="wrapper">
          <Navbar />

          <Routes>
            <Route path="Home" element={<Home />} />
            <Route path="Bio" element={<Bio />} />
            <Route path="Curriculum" element={<Curriculum />} />
            <Route path="Form" element={<Form />} />
            <Route path="Ritratti" element={<Ritratti />} />
            <Route path="Studi" element={<Studi />} />
            <Route path="Cassandra" element={<Cassandra />} />
            <Route path="Artemisia" element={<Artemisia />} />
            <Route path="Ispirami" element={<Ispirami />} />
  <Route path="Statement" element={<Statement />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
