import React from "react";
import "./Ritratti.css";
import alledonne from "../assets/Dipinti/7Alledonne.jpg";
import carolina from "../assets/Dipinti/8Carolina.jpg";
import heroides1 from "../assets/Dipinti/1heroides1.jpg";
import heroides2 from "../assets/Dipinti/2heroides2.jpeg";
import heroides3 from "../assets/Dipinti/3heroides3.jpeg";
import viviana from "../assets/Dipinti/4Viviana.jpg";
import amanta from "../assets/Dipinti/5amantha.jpg";
import marwa from "../assets/Dipinti/6Marwa.jpg";
import rita from "../assets/Dipinti/9rita.jpg";
import medusa from "../assets/Dipinti/medusa.jpg";
import decoro from "../assets/Freccia.svg";
import caramanu from "../assets/studi/1caramanu.jpg";
import nelleforme from "../assets/Dipinti/nelleforme.png";
import salvatore from "../assets/studi/3salvatore.jpg";
import studiodisal from "../assets/studi/4studiodisal.jpg";
import lacarezza from "../assets/studi/5lacarezza.jpg";
import studio1 from "../assets/Dipinti/studio1.jpeg";
import studiodue from "../assets/Dipinti/studiodue.jpeg";


const Ritratti = () => {
  return (
    <>
      <div className="operecontainer">
        <div className="operecontainerin">
          <div className="divisione1"> Ritratti </div>
          <div className="gallery2">
            <img src={heroides1} alt="Heroides1" width="600" height="400" />

            <div class="desc">
              HEROIDES I <br />
              olio e foglia oro su tela <br />
              60 x 80 cm <br />
              2020
            </div>
          </div>
          <div className="gallery3">
            <img src={heroides2} alt="Forest" width="600" height="400" />

            <div class="desc">
              HEROIDES II <br />
              olio e foglia oro su tela <br />
              60 x 80 cm <br />
              2020
            </div>
          </div>
          <div className="gallery4">
            <img
              src={heroides3}
              alt="Northern Lights"
              width="600"
              height="400"
            />

            <div class="desc">
              HEROIDES III <br />
              olio e foglia oro su tela <br />
              60 x 80 cm <br />
              2021
            </div>
          </div>
          <div className="gallery5">
            <img src={medusa} alt="Mountains" width="600" height="400" />

            <div class="desc">
              MEDUSA TRIONFANTE <br />
              DECOLLA PERSEO <br />
              olio e foglia oro su tela <br />
              80 x 120 cm <br />
              2021
            </div>
          </div>
          <div className="gallery6">
            <img src={alledonne} alt="Mountains" width="600" height="400" />

            <div class="desc">
              A TUTTE LE DONNE LIBERE
              <br />
              olio e foglia oro su tela <br />
              40 x 50 cm <br />
              2021
            </div>
          </div>
          <div className="gallery7">
            <img src={amanta} alt="Mountains" width="600" height="400" />

            <div class="desc">
              AMANTHA <br />
              olio e foglia oro su tela <br />
              40 x 50 cm <br />
              2022
            </div>
          </div>
          <div className="gallery8">
            <img src={marwa} alt="Mountains" width="600" height="400" />

            <div class="desc">
              MARWA <br />
              olio su tela <br />
              40 x 50 cm <br />
              2022
            </div>
          </div>
          <div className="gallery9">
            <img src={carolina} alt="Mountains" width="600" height="400" />

            <div class="desc">
              CAROLINA <br />
              olio e foglia oro su tela <br />
              30 x 40 cm <br />
              2021
            </div>
          </div>
          <div className="gallery10">
            <img src={viviana} alt="Mountains" width="600" height="400" />

            <div class="desc">
              VIVIANA <br />
              olio e foglia oro su tela <br />
              50 x 70 cm <br />
              2022
            </div>
          </div>

          <div className="divisione2"> Altri Progetti </div>

          <div className="gallery11">
            <img src={nelleforme} alt="Mountains" width="600" height="400" />

            <div class="desc2">
              NELLE FORME DI UN ALTRO DOLORE <br />
              tecnica mista e foglia oro su tela <br />
              90 cm <br />
              2022
            </div>
          </div>

          <div className="gallery12">
            <img src={studio1} alt="Mountains" width="600" height="400" />

            <div class="desc2">
              STUDIO <br />
              tecnica mista su tela grezza <br />
              55 x 65 cm <br />
              2023
            </div>
          </div>

          <div className="gallery13">
            <img src={studiodue} alt="Mountains" width="600" height="400" />

            <div class="desc2">
              STUDIO II <br />
              tecnica mista su tela grezza <br />
              55 x 65 cm <br />
              2023
            </div>
          </div>

          <div className="gallery14">
            <img src={caramanu} alt="Mountains" width="600" height="400" />

            <div class="desc2">
              CARA MANU <br />
              collage su tela <br />
              30 x 40 cm <br />
              2022
            </div>
          </div>

          <div className="gallery15">
            <img src={salvatore} alt="Mountains" width="600" height="400" />

            <div class="desc2">
              SALVATORE <br />
              olio, tela grezza e gesso su tela <br />
              100 x 100 cm <br />
              2019
            </div>
          </div>

          <div className="gallery21">
            <a href="Home">Torna alla home</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ritratti;
