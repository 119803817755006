import "./HeroImgStyles.css";
import dipinti from "../assets/Dipinti/studio1.jpeg";
import artemisia from "../assets/slidemenu/artemisia.jpg";
import cassandra from "../assets/slidemenu/cassandra.jpeg";
import ispirami from "../assets/slidemenu/ispirami.jpg";
import studi from "../assets/slidemenu/studi.jpg";
import React from "react";
import { Link } from "react-router-dom";

const HeroImg = () => {
  return (
    <div className="hero">
      <div className="content"></div>
      <div class="slideone">
        <Link to="/Ritratti">
          <div className="slideone">
            <img src={dipinti} alt="primom" draggable="false" />

            <div class="slideoneoverlay">
              <h1>Galleria </h1>{" "}
            </div>
          </div>
        </Link>
      </div>

      <div class="slidethree">
        <Link to="/Cassandra">
          <div className="slidethree">
            <img src={cassandra} alt="terzom" draggable="false" />

            <div class="slidethreeoverlay">
              <h1>
                {" "}
                Parla <br /> Cassandra{" "}
              </h1>{" "}
            </div>
          </div>
        </Link>
      </div>
      <div class="slidefour">
        <Link to="/Ispirami">
          <div className="slidefour">
            <img src={ispirami} alt="quintom" draggable="false" />

            <div class="slidefouroverlay">
              <h1>Ispirami</h1>{" "}
            </div>
          </div>
        </Link>
      </div>
      <div class="slidefive">
        <div className="slidefive">
          <img src={ispirami} alt="quintom" draggable="false" />

          <div class="slidefiveoverlay">
            <h1>Ispirami</h1>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImg;



