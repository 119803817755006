import React from "react";
import "./Ritratti.css";
import arte1 from "../assets/artemisia/arteuno.jpg";
import arte2 from "../assets/artemisia/artedue.jpg";
import arte3 from "../assets/artemisia/artetre.jpg";
import arte4 from "../assets/artemisia/artequattro.jpg";
import "./Artemisia.css";

const Artemisia = () => {
  return (
    <div className="operecontainer">
      <div className="operecontainerin">
        <div className="gallery2">
          
            <img src={arte1} alt="Heroides1" width="600" height="400" />
          
        </div>
        <div className="gallery3">
          
            <img src={arte2} alt="Forest" width="600" height="400" />
          
        </div>
     
        <div className="testoartemisia">
          <h1>
            'Artemisia: un progetto contro la violenza di genere’ 
            nasce nell’estate del 2021 per le strade di Napoli. 
            
            Il vissuto di Artemisia Gentileschi 
            è stato l’input di questa iniziativa, 
            la semplice idea di renderle omaggio
            
            ricreando il suo autoritratto più famoso
            
            si trasforma nell’opportunità di coinvolgere il pubblico. 
            Come?
          
            ‘‘Vorrei dedicare questo dipinto a tutte le donne.
            
            Se sei donna (cis, trans) 
            ed hai subito una qualsiasi violenza,
            ti invito a firmare questo tributo.’’
            
            Così recitava il messaggio alle mie spalle 
            mentre il quadro prendeva vita 
            attraverso sessioni di live painting nel cuore di Napoli.
            
            Ogni volta che una donna 
            lasciava il proprio nome sulla tela, 
            vedevo la vita vissuta unirsi all’arte.
            
            Il dipinto, una volta terminato,
            
            è stato donato all’associazione  “Volontarie del Telefono Rosa
            Napoli”,
            
            che dedica la sua attività al supporto  delle donne vittima di
            violenza
             sul territorio campano, 
            verso la quale è stata anche creata
             una raccolta fondi di beneficenza.
          </h1>
        </div>
           <div className="gallery13">
                            
                            <a href="Home">
          Torna alla home
        </a>
        
              </div>   
      </div>
    </div>
  );
};

export default Artemisia;
