import "./FormStyles.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contatti = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Thank you, message sent!");

    emailjs
      .sendForm(
        "service_9chtyh4",
        "template_p2lcizi",
        form.current,
        "J0UL3pFG8kBcitt0O"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const notify = () =>
    toast("Messaggio inviato!", {
      position: toast.POSITION.TOP_RIGHT
    });

  return (
    <>
      <div className="form">
        <form ref={form} onSubmit={sendEmail}>
          <label>Il tuo nome</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Il tuo messaggio </label>
          <textarea
            rows="6"
            placeholder="Type your message here"
            name="message"
          />
          <button className="invia" type="submit" onClick={notify}>
            Invia
          </button>
          <ToastContainer />
        </form>
        <div className="insta"></div>
        <div>
          <a
            className="instacliccato"
            href="https://www.instagram.com/emanuela.auricchio/"
          >
            @emanuela.auricchio
          </a>
          <h4> </h4>
          <a
            className="instacliccato"
            href="https://www.instagram.com/cassandra.parla/"
          >
            @cassandra.parla
          </a>
                 <h4> </h4>
          <a
            className="instacliccato"
            href="mailto:emanuelaauricchio2@gmail.com"
          >
           emanuelaauricchio2@gmail.com
          </a>
        </div>
      </div>
    </>
  );
};

export default Contatti;
