import React from "react";
import "./Ritratti.css";
import uno from "../assets/cassandra/1.jpg";
import due from "../assets/cassandra/2.jpeg";
import tre from "../assets/cassandra/3.jpg";
import quattro from "../assets/cassandra/4.jpeg";
import cinque from "../assets/cassandra/5.jpeg";
import sei from "../assets/cassandra/6.jpeg";
import sette from "../assets/cassandra/7.jpg";
import otto from "../assets/cassandra/8.jpg";
import "./Cassandra.css";

const Cassandra = () => {
  return (
     
    <div className="operecontainer">
      <div className="operecontainerin">
      <div className="gallery2">
       
          <img src={uno} alt="Heroides1" width="600" height="400" />
        
        <div class="desc">
          {" "}
          PORT’ALBA <br /> NAPOLI

        </div>
      </div>
      <div className="gallery2">
      
          <img src={due} alt="Forest" width="600" height="400" />
       
        <div class="desc">
        SASSARI 
        </div>
      </div>
      <div className="gallery2">
      
          <img src={tre} alt="Northern Lights" width="600" height="400" />
        
        <div class="desc">
   NAPOLI
        </div>
      </div>
      <div className="gallery2">
        
          <img src={quattro} alt="Mountains" width="600" height="400" />
        
        <div class="desc">
          TORINO
        </div>
      </div>
      <div className="gallery2">
       
          <img src={cinque} alt="Mountains" width="600" height="400" />
       
        <div class="desc">
          TITO
        </div>
      </div>
      <div className="gallery2">
        
          <img src={sei} alt="Mountains" width="600" height="400" />
        
        <div class="desc">
          {" "}
         FIRENZE
        </div>
      </div>
      <div className="gallery2">
      
          <img src={sette} alt="Mountains" width="600" height="400" />
        
        <div class="desc">
          {" "}
          T-SHIRT PERSONALIZZATE <br />
          CASSANDRA PARLA ©<br />
    
        </div>
      </div>
      <div className="gallery2">
      
          <img src={otto} alt="Mountains" width="600" height="400" />
       
        <div class="desc">
        {" "}
         TOTE BAG PERSONALIZZATE <br />
          CASSANDRA PARLA ©<br />
        </div>
 
        <div className="testocassandra">
  <h1>
             Cassandra Parla, progetto di street-art nato nel cuore di Napoli, nasce dall’idea di un’arte condivisa e si diffonde successivamente in tutta Italia. Rivendicare i diritti umani è il messaggio che esprimono i miei dipinti, affissi come manifesti sui muri delle città, nella speranza di incontrare occhi in cui rispecchiarsi. “M’he fatt ‘a piezz pe’ nun m’affruntà sana” apre questo progetto che parla napoletano, la lingua della mia terra, nell’intenzione di restituire a Napoli, ciò che Napoli ha donato alla mia arte.
 
            </h1>
        </div>
                 
      </div>
           
      
            <div className="gallery31">
                            
                            <a href="Home">
          Torna alla home
        </a>
        
              </div>        
                   
              </div>   
                </div>   
     
   
   
  );
};

export default Cassandra;
