import React from "react";
import "./Bio.css";

const Statement = () => {
  return (
    <div className="textcontainer">
      <div className="titolostat">
        <h2> STATEMENT </h2>
      </div>
      <div className="testostat">
        <h1>
     Il lavoro indaga le connessioni umane accentrando il focus sulla partecipazione e la relazione tra l’opera e il pubblico. Lo studio del colore, della materia e della sua modulazione sono in costante sperimentazione: i progetti nascono dall’incontro, che ad oggi è una tematica da affrontare anche sotto l’aspetto di altri medium. L’interesse di un’arte condivisa, che parli dell’essere umano è l’ispirazione della produzione artistica.

        </h1>
      </div>
    </div>
  );
};

export default Statement;
